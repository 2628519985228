import Image from 'next/image';
import classNames from 'classnames';
import Link from 'next/link';
import styles from './index.module.less';
import { AppSettings, pageMetadataMap, PageType } from '@/shared/app-common';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { log } from 'console';

const GlobalFooter = ({
  show = true,
  className,
  pageType,
}: {
  show?: boolean;
  className?: string;
  pageType: PageType;
}) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  if (!show) {
    return <></>;
  }
  const cls = classNames(styles.globalFooterWarp, className);
  // const pathname = (typeof window !== 'undefined') ? window.location.pathname : "/"

  return (
    <div className={cls}>
      <div className={styles.titleContainer}>
        {/* GPTs Logo */}
        <div style={{ height: '32px' }}>
          <Image
            className={styles.logo}
            src={AppSettings.logoPath}
            alt={AppSettings.name}
            loading="lazy"
            width={32}
            height={32}
          />
          <Image
            src={AppSettings.namePath}
            alt={AppSettings.name}
            style={{ height: '32px', marginLeft: '10px' }}
            width={154}
            height={32}
            loading="lazy"
          />
        </div>
        <div className={styles.routerSection}>
          <Link href={`${AppSettings.webHost}/instagram-tracking-example`} className={styles.link}>
            {t('Analytics Sample')}
          </Link>
          <Link href={`${AppSettings.webHost}/starData`} className={styles.link}>
            {t('Celebrity Influencers')}
          </Link>
          <Link href={`${AppSettings.webHost}/view-instagram-anonymously`} className={styles.link}>
            {t('Instagram Story Viewer')}
          </Link>

          <Link href={`${AppSettings.webHost}/plan`} className={styles.link}>
            {t('Pricing')}
          </Link>
          <Link href={`${AppSettings.blogHost}/instagram-tracking-blog`} className={styles.link}>
            {t('blog')}
          </Link>
          <Link href={`${AppSettings.webHost}/instagram-tracking-faq`} className={styles.link}>
            {t('FAQ')}
          </Link>
          <Link href={`${AppSettings.webHost}/toolkit`} className={styles.link}>
            {t('Free Tools')}
          </Link>
        </div>

        <div className={styles.section}>
          <Link href="https://twitter.com/DolphinRadar" target="__block" className={styles.link}>
            <img src="/images/layouts/x-link-icon.webp" alt="x" />
          </Link>
          <Link
            href="https://www.facebook.com/profile.php?id=61558394549930"
            target="__block"
            className={styles.link}
          >
            <img src="/images/layouts/fb-link-icon.webp" alt="facebook" />
          </Link>
        </div>
      </div>

      <div className={styles.bottomDescContainer}>
        <span
          dangerouslySetInnerHTML={{
            __html: t('IfYouHaveAnyIssuesPleaseClickThisFormToProvideFeedback_Desc'),
          }}
        />
      </div>
      {Object.entries(PageType)
        .filter(([, value]) => value !== pageType)
        .map(([key, value]) => {
          // console.log(value, PageType.Normal === value);
          const path = value === PageType.Normal
            ? AppSettings.webHost
            : `${AppSettings.webHost}${router.locale && router.locale !== 'en' ? `/${router.locale}` : ''}/${value}`;
          const pageMetaData = pageMetadataMap[value];
          const content =
            value === PageType.Normal
              ? 'DolphinRadar: Your Ultimate Instagram Activity Tracker'
              : t(pageMetaData.titleKey).replace('| DolphinRadar', '');
          return (
            <div className={styles.bottomDescContainer} key={key}>
              <a href={path}>
                <span>{content}</span>
              </a>
            </div>
          );
        })}

      <div className={styles.bottomContainer}>
        <div className={styles.subTitleContainer}>All rights reserved. © 2024 Dolphin Radar</div>

        <div className={styles.section}>
          <Link href={`${AppSettings.webHost}/privacy-policy`} className={styles.link}>
            {t('privacyPolicy')}
          </Link>
          <Link href={`${AppSettings.webHost}/aboutus`} className={styles.link}>
            {t('termsOfUs')}
          </Link>
        </div>
        <div className={styles.subTitleContainer}>All rights reserved. © 2024 Dolphin Radar</div>
      </div>
    </div>
  );
};

export default GlobalFooter;
